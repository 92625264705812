import logoLight from '../../../assets/img/logo.png'
import logoDark from '../../../assets/img/logo-dark.png'

let theme = localStorage.getItem('theme')

let cssDark = 'index.90bb3334.css'
let cssLight = 'index.d8003e19.css'

let toggle = document.getElementById('toggle')
let logo = document.querySelector('.logo-img')
let logoMenu = document.querySelector('.logo-img-menu')
let logoModal = document.querySelectorAll('.logo-modal')
let css = document.querySelectorAll('link')[4]

let setTheme = () => {
	if(toggle.textContent === 'toggle_off'){
		toggle.textContent = 'toggle_on'
		logo.src = logoLight
		logoMenu.src = logoLight
		logoModal.forEach(lo => {
			lo.src = logoLight
		})
		css.href = cssDark
		localStorage.setItem('theme','dark')
	}else{
		toggle.textContent = 'toggle_off'
		logo.src = logoDark
		logoMenu.src = logoDark
		logoModal.forEach(lo => {
			lo.src = logoDark
		})
		css.href = cssLight
		localStorage.setItem('theme','light')
	}
}

if(theme === 'light'){
	toggle.textContent = 'toggle_on'
}else{
	toggle.textContent = 'toggle_off'
}

setTheme()

toggle.addEventListener('click', () => setTheme(), false)

import QrScanner from 'qr-scanner';

let videoElem = document.querySelector('#render_qr')
let renderData = document.querySelector('.render-data')
let startScan = document.querySelector('.item-scanner')
let closeScan = document.querySelector('.modal-scanner-close')

const qrScanner = new QrScanner(videoElem, result => {
    renderData.style.display = 'flex'
    renderData.style.flexDirection = 'column'
    renderData.style.justifyContent = 'center'
    renderData.style.alignItems = 'center'
    renderData.style.textAlign = 'center'
    renderData.style.zIndex = '2'
    if(result.data.startsWith('tel:')){
        let tel = result.data.replace('tel:','')
        renderData.innerHTML = "<a href='tel:"+ tel +"' style='font-size: 2rem; margin-top: 25px;'>Téléphoner au "+ tel +"</a>"
    }else if(result.data.startsWith('http:') || result.data.startsWith('https:')){
        let website = result.data
        renderData.innerHTML = "<a href='"+ website +"' style='font-size: 2rem; margin-top: 25px;'>Aller sur le site "+ website +"</a>"
    }else if(result.data.startsWith('mailto:')){
        let mail = result.data.replace('mailto:','')
        renderData.innerHTML = "<a href='mailto:"+ mail +"' style='font-size: 2rem; margin-top: 25px;'>Envoyer un mail à "+ mail.split('?')[0] +"</a>"
    }else if(result.data.startsWith('geo:')){
        let geo = result.data.replace('geo:','')
        renderData.innerHTML = "<a href='https://www.google.com/maps/search/?api=1&query="+ geo.split(',')[0] +"%2C"+ geo.split(',')[1] +"' style='font-size: 2rem; margin-top: 25px;'>Ouvrir la position géographique latitute: "+ geo.split(',')[0] +" lontitude: "+ geo.split(',')[1] +"</a>"
    }else if(result.data.startsWith('SMSTO:')){
        let sms = result.data.replace('SMSTO:','')
        let smsnum = sms.split(':')[0]
        let smsbody = sms.split(':')[1]
        renderData.innerHTML = "<a href='sms:"+ smsnum +"&body="+ smsbody +"' style='font-size: 2rem; margin-top: 25px;'>Envoyer un sms à "+ smsnum +"<br >Contenu du message: "+ smsbody +"</a>"
    }else{
        renderData.innerHTML = "<p>"+ result.data +"</p>"
    }
    qrScanner.stop();
}, { highlightScanRegion:true, highlightCodeOutline:true });


startScan.addEventListener('click', () => {
    qrScanner.start();
}, false)

closeScan.addEventListener('click', () => {
    renderData.innerHTML = ''
    qrScanner.stop();
}, false)

document.querySelector('.scan-region-highlight-svg').style.stroke = 'dodgerblue'

let cards = ['.card-tel','.card-sms','.card-gps','.card-vcard','.card-web','.card-texte','.card-even','.card-mail','.card-sepa']
let forms = ['.form-tel','.form-sms','.form-gps','.form-vcard','.form-web','.form-texte','.form-even','.form-mail','.form-sepa']

cards.forEach(card => {
	document.querySelector(card).addEventListener('click', () => {
		show(card.replace('.card','.form'))
	}, false)
})

const show = (form) => {
	forms.forEach(fm => { 
		if(form === fm){
			document.querySelector(fm).style.display = 'flex'
		}else{
			document.querySelector(fm).style.display = 'none'
		} 
	})
}

export default show;
let shadow = document.querySelector('.shadow')
let menu = document.querySelector('.menu')
let btnMenu = document.querySelector('.btn-menu')

let itemHome = document.querySelector('.item-home')
let itemAuthor = document.querySelector('.item-author')
let itemAbout = document.querySelector('.item-about')
let itemScanner = document.querySelector('.item-scanner')

let modalAuthor = document.querySelector('.modal-author')
let modalAuthorClose = document.querySelector('.modal-author-close')

let modalAbout = document.querySelector('.modal-about')
let modalAboutClose = document.querySelector('.modal-about-close')

let modalScanner = document.querySelector('.modal-scanner')
let modalScannerClose = document.querySelector('.modal-scanner-close')

modalScannerClose.addEventListener('click', () => {
	shadow.style.transition = 'top linear .3s'
	modalScanner.style.display = 'none'
}, false)

modalAboutClose.addEventListener('click', () => {
	shadow.style.transition = 'top linear .3s'
	modalAbout.style.display = 'none'
}, false)

modalAuthorClose.addEventListener('click', () => {
	shadow.style.transition = 'top linear .3s'
	modalAuthor.style.display = 'none'
}, false)

itemHome.addEventListener('click', () => {
	shadow.style.top = '-100%'
	menu.style.right = '-300px'
}, false)

itemAbout.addEventListener('click', () => {
	shadow.style.transition = 'none'
	shadow.style.top = '-100%'
	menu.style.right = '-300px'
	modalAbout.style.display = 'flex'
}, false)

itemAuthor.addEventListener('click', () => {
	shadow.style.transition = 'none'
	shadow.style.top = '-100%'
	menu.style.right = '-300px'
	modalAuthor.style.display = 'flex'
}, false)

itemScanner.addEventListener('click', () => {
	shadow.style.transition = 'none'
	shadow.style.top = '-100%'
	menu.style.right = '-300px'
	modalScanner.style.display = 'flex'
}, false)

btnMenu.addEventListener('click', () => {
	shadow.style.top = '0%'
	menu.style.right = '0px'
}, false)

shadow.addEventListener('click', () => {
	shadow.style.top = '-100%'
	menu.style.right = '-300px'
}, false)

import QRious from "qrious"
import { girocode, bezahlcode } from 'iban-qr-code'

let btns = ['.btn-tel','.btn-sms','.btn-gps','.btn-vcard','.btn-web','.btn-texte','.btn-even','.btn-mail','.btn-sepa']

btns.forEach(btn => {
    document.querySelector(btn).addEventListener('click', () => {
        if(btn === '.btn-web'){
            qrcode_website()
        }else if(btn === '.btn-tel'){
            qrcode_tel()
        }else if(btn === '.btn-sms'){
            qrcode_sms()
        }else if(btn === '.btn-gps'){
            qrcode_gps()
        }else if(btn === '.btn-vcard'){
            qrcode_vcard()
        }else if(btn === '.btn-texte'){
            qrcode_texte()
        }else if(btn === '.btn-even'){
            qrcode_even()
        }else if(btn === '.btn-mail'){
            qrcode_mail()
        }else if(btn === '.btn-sepa'){
            qrcode_sepa()
        }
    }, false)
})

let qrcode_sepa = () => {
    let giro = girocode({
        name: document.querySelector("input[name='sepa-name']").value,
        iban: document.querySelector("input[name='sepa-iban']").value,
        bic: document.querySelector("input[name='sepa-bic']").value,
        currency: 'EUR',
        amount: document.querySelector("input[name='sepa-price']").value,
        char: '',  // Purpose of the Credit Transfer (AT-44)
        ref: '',  // ISO 11649 RF Creditor Reference may be used here
        reason: document.querySelector("input[name='sepa-reason']").value,
        hint: '',  // note to user,
        version: '002'  // Version of Quick Response Code 001 or 002
      })

      let qr = new QRious({
          element: document.getElementById('qr'),
          value: giro,
          size: 125
      });
      download()
}

// Create qrcode mail
let qrcode_mail = () => {
    let addr = document.querySelector("input[name='mail-addr']").value
    let subject = document.querySelector("input[name='mail-subject']").value
    let content = document.querySelector("input[name='mail-content']").value

    let val = `mailto:`+ addr +`?&subject=`+ subject +`&body=`+ content
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    }); 
    download()
}

// Create qrcode evenement
let qrcode_even = () => {
    let titre = document.querySelector("input[name='even-title']").value
    let start = document.querySelector("input[name='even-start']").value.replace('-','').replace(':','').replace('-','') +'00'
    let end = document.querySelector("input[name='even-end']").value.replace('-','').replace(':','').replace('-','') +'00'
    let location = document.querySelector("input[name='even-location']").value
    let description = document.querySelector("input[name='even-description']").value

    let val = `
        BEGIN:VCALENDAR
        VERSION:3.0
        BEGIN:VEVENT
        DTSTART:`+ start +`
        DTEND:`+ end +`
        SUMMARY:`+ titre +`
        LOCATION:`+ location +`
        DESCRIPTION:`+ description +`
        PRIORITY:3
        END:VEVENT
        END:VCALENDAR
    `
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create text qrcode
let qrcode_texte = () => {
    let val = document.querySelector("input[name='texte']").value
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create qrcode vcard
let qrcode_vcard = () => {
    let titre = document.querySelector("input[name='vcard-title']").value
    let nom = document.querySelector("input[name='vcard-nom']").value
    let prenom = document.querySelector("input[name='vcard-prenom']").value
    let email = document.querySelector("input[name='vcard-email']").value
    let tel = document.querySelector("input[name='vcard-tel']").value
    let url = document.querySelector("input[name='vcard-url']").value

    let val = "BEGIN:VCARD\r\n" +
        "VERSION:3.0\r\n" +
        "N:"+ nom +";"+ prenom +";\r\n" +
        "FN:"+ nom +" "+ prenom +"\r\n" +
        "TITLE:"+ titre +"\r\n" +
        "EMAIL;TYPE=INTERNET;TYPE=BUSINESS;TYPE=PREF:"+ email +"\r\n" +
        "TEL:"+ tel +"\r\n" +
        "URL;TYPE=Homepage:"+ url +"\r\n" +
        "END:VCARD\r\n";
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create qrcode GPS
let qrcode_gps = () => {
    let lat = document.querySelector("input[name='gps-lat']").value
    let lon = document.querySelector("input[name='gps-lon']").value
    let val = "geo:"+ lat +","+ lon
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create qrcode sms
let qrcode_sms = () => {
    let tel = document.querySelector("input[name='sms-tel']").value
    let content = document.querySelector("input[name='sms-content']").value
    let val = 'SMSTO:'+ tel +'\r\n:'+ content
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create phone qrcode
let qrcode_tel = () => {
    let val = 'tel:'+ document.querySelector("input[name='tel']").value
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Create website qrcode
let qrcode_website = () => {
    let val = document.querySelector("input[name='web']").value 
    let qr = new QRious({
        element: document.getElementById('qr'),
        value: val,
        size: 125
    });
    download()
}

// Force le téléchargement des qrcodes une fois générer
let download = () => {
    let link = document.createElement('a')
    link.setAttribute('download', 'qrcode.png');
    link.setAttribute('href', qr.toDataURL("image/png"));
    link.click();
}

// link.setAttribute('href', qr.toDataURL("image/png").replace("image/png", "image/octet-stream"));